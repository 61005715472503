import { Preferences } from '@capacitor/preferences';
import { cookieDomain, getAmplifyConfig } from '../amplifyUtils';


// This function removes Cognito cookies with a client ID that does not match the current one
export const removeBadCognitoCookies = (): void => {
  const cookies = document.cookie.split(';');
  const userPoolClientId = getAmplifyConfig().Auth.Cognito.userPoolClientId;

  cookies.forEach(cookie => {
    const k = cookie.split('=')[0];
    const kTrimmed = k.trim();
    if (kTrimmed.startsWith('CognitoIdentityServiceProvider') || kTrimmed.startsWith('amplify')) {
      if (!kTrimmed.startsWith(`CognitoIdentityServiceProvider.${userPoolClientId}`)) {
        const newCookie = k + `=; Domain=${cookieDomain}; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
        document.cookie = newCookie;
      }
    }
  });
};

// This function erases localstorage tokens with a client ID that does not match the current one
export const removeBadLocalStorageTokens = (): void => {
  const userPoolClientId = getAmplifyConfig().Auth.Cognito.userPoolClientId;

  Object.keys(localStorage).forEach(key => {
    if (key.startsWith('CognitoIdentityServiceProvider')) {
      if (!key.startsWith(`CognitoIdentityServiceProvider.${userPoolClientId}`)) {
        localStorage.removeItem(key);
      }
    }
  });
};

export const removeLocalStorageOnboarding = async (): Promise<void> => {
  const keys = await Preferences.keys();
  keys.keys.filter(key => key.startsWith('onboardingStep.') || key.startsWith('completedStep.')).forEach(key => {
    Preferences.remove({ key });
  });
};